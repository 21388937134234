export const StorageBox = {
  saveNewProduct: (data: any) => {
    const encryptedData = JSON.stringify(data);
    localStorage.setItem("products", encryptedData);
  },
  retrieveProducts: () => {
    const encryptedData = localStorage.getItem("products");
    if (encryptedData !== null) {
      const decryptedData = JSON.parse(encryptedData);
      // console.log(decryptedData);
      return decryptedData;
    } else {
      return [];
    }
  },
  clearStorage: () => {
    localStorage.clear();
  },
};

import { Modal, Form, Input, notification, Spin, Upload, Checkbox } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { BaseServices } from '../../../helpers/baseServices';
import { IoCloseOutline } from 'react-icons/io5';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { StorageBox } from '../../../helpers/stroage';

interface modalProps {
    isOpened: boolean;
    handleClose: () => void;
    handleReFetch: () => void;
}


const AddProductModal = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const [form] = useForm();
    const [productImages, setProductImages] = useState<any[]>([{ file: null, url: "" }, { file: null, url: "" }, { file: null, url: "" }, { file: null, url: "" }, { file: null, url: "" }]);
    const [isTabgible, setIsTabgible] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [generatingAiName, setGeneratingAiName] = useState(false);
    const [generatingAiDescription, setGeneratingAiDescription] = useState(false);

    const [fileList, setFileList] = useState<any[]>([]);


    //handleImageChange
    const handleChange = ({ fileList }: any) => {
        // Limit file list to a maximum of 5 files
        if (fileList.length <= 5) {
            setFileList(fileList);
        } else {
            notification.warning({
                message: 'Image Limit Exceeded',
                description: 'You can only upload up to 5 images.',
            });
        }
    };

    //uploadImage
    const uploadImage = async (file: any, index: number) => {
        if (file) {
            let data = new FormData();
            data.append('images', file);

            try {
                const response = await BaseServices.post(`/api/v1/elep/upload-item-images`, data);
                console.log('___imgUploadRes:', response?.data);
                const newImages = [...productImages];
                newImages[index] = { file, url: response?.data?.data?.[0] || '' };
                setProductImages(newImages);
                return response?.data?.data?.[0] || '';
            } catch (error: any) {
                console.log('___imgUploadErr:', error);
                notification.error({
                    message: 'Error',
                    description: error?.response?.data?.data || 'An error occurred while uploading image, please try again later',
                });
                return null;
            }
        }
    };

    //handlePreview
    const handlePreview = async (file: any) => {
        Modal.info({
            title: 'Image Preview',
            content: <img alt="preview" style={{ width: '100%' }} src={file?.url || file?.preview} />,
            okText: 'Close',
        });
    };

    //handleRemoveImage
    const handleRemoveImage = (file: any) => {
        setFileList((prevList) => prevList.filter((item: any) => item?.uid !== file?.uid));
    };

    //generateAiDescription
    const generateAiDescription = async () => {
        if (fileList?.length === 0) {
            notification.error({ message: "Error", description: "Please upload a product image" });
        } else {
            let data = new FormData();
            data.append("image", fileList?.[0]?.originFileObj);

            setGeneratingAiDescription(true);

            try {
                const response = await BaseServices.post(`/api/v1/elep/generate-description`, data);
                console.log("___generateAiDescriptionResponse", response?.data);
                form.setFieldsValue({ description: response?.data?.data?.description || "" });
                setGeneratingAiDescription(false);
            } catch (error: any) {
                console.log("___generateAiDescriptionError", error);
                notification.error({ message: "Error", description: error?.response?.data?.data || "An error occurred, please try again later" });
                setGeneratingAiDescription(false);
            }
        }
    }

    //generateAiName
    const generateAiName = async () => {
        if (fileList?.length === 0) {
            notification.error({ message: "Error", description: "Please upload a product image" });
        } else {
            let data = new FormData();
            data.append("image", fileList?.[0]?.originFileObj);

            setGeneratingAiName(true);

            try {
                const response = await BaseServices.post(`/api/v1/elep/generate-description`, data);
                console.log("___generateAiNameResponse", response?.data);
                form.setFieldsValue({ name: response?.data?.data?.name || "" });
                setGeneratingAiName(false);
            } catch (error: any) {
                console.log("___generateAiNameError", error);
                notification.error({ message: "Error", description: error?.response?.data?.data || "An error occurred, please try again later" });
                setGeneratingAiName(false);
            }
        }
    }


    //handleCreateProduct
    const handleCreateProduct = async (form_values: any) => {
        if (fileList.length === 0) {
            notification.error({
                message: 'No Images Uploaded',
                description: 'Please upload at least one image.',
            });
            return;
        }

        setIsCreating(true);

        try {
            // Upload each image
            const uploadPromises = fileList.map((file: any, index: number) => uploadImage(file?.originFileObj, index));
            const imageUrls = await Promise.all(uploadPromises);

            // Remove any null URLs
            const validImageUrls = imageUrls.filter((url) => url);

            // Prepare product data
            let productData: any = {
                '_id': Array(4).fill(null).map(() => Math.random().toString(36).substring(2)).join('').substring(0, 24),
                'name': form_values?.name,
                'price': form_values?.price,
                'qty_available': Number(form_values?.qty_available),
                'description': form_values?.description,
                'is_tangible': isTabgible,
                'images': validImageUrls,
            };

            const old_data = StorageBox.retrieveProducts();

            // Simulate saving product
            setTimeout(() => {
                const updated_data = [productData, ...old_data];
                StorageBox.saveNewProduct(updated_data);
                notification.success({
                    message: 'Success',
                    description: 'Product created successfully',
                });
                setIsCreating(false);
                setFileList([]);
                handleClose();
                handleReFetch();
            }, 2000);

        } catch (error) {
            console.error('Error creating product:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to create the product. Please try again.',
            });
            setIsCreating(false);
        }
    };


    return (
        <>
            <Modal
                width={700}
                open={isOpened}
                onCancel={handleClose}
                footer={null}
                maskClosable={false}
                keyboard={false}
                closeIcon={false}
                centered
            >
                <div className="h-[500px] overflow-y-auto px-3">
                    <Spin spinning={isCreating}>
                        <div className="flex justify-between items-center">
                            <p className='text-[20px] font-[600]'>Product listing</p>
                            <button className='h-6 w-6 hover:bg-[#000] hover:bg-opacity-10 rounded flex justify-center items-center' onClick={handleClose}>
                                <IoCloseOutline className='h-5 w-5' />
                            </button>
                        </div>

                        <Form
                            form={form}
                            className='mt-5'
                            layout="vertical"
                            onFinish={handleCreateProduct}
                        >
                            {/* product images */}
                            <Form.Item className='mt-5'>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    onRemove={handleRemoveImage}
                                    multiple
                                    beforeUpload={() => false} // Disable automatic upload
                                >
                                    {fileList.length < 5 && (
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>Upload</div>
                                        </div>
                                    )}
                                </Upload>
                            </Form.Item>

                            {/* productName */}
                            <Form.Item
                                label={<div className='flex gap-3'>
                                    <p>Product Name</p>
                                    {
                                        generatingAiName ?
                                            <div>
                                                <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: '#000' }} spin />} />
                                            </div> :
                                            <button type='button' className='py-[2px] px-[8px] border border-[#000] rounded-[40px] text-[12px] font-[500] text-[#344054]' onClick={generateAiName}>
                                                Generate with Ai
                                            </button>
                                    }
                                </div>}
                                name='name'
                                rules={[{ required: true, message: 'Product name is required' }]}
                            >
                                <Input className="h-[40px]" />
                            </Form.Item>

                            <div className="mt-[14px] 2xl:grid xl:grid lg:grid md:grid grid-cols-2 gap-x-5 items-center">
                                {/* price */}
                                <Form.Item
                                    label='Product Price'
                                    name='price'
                                    rules={[{ required: true, message: 'Price is required' }]}
                                >
                                    <Input className="h-[40px]" />
                                </Form.Item>

                                {/* quantity */}
                                <Form.Item
                                    label='Product Quantity'
                                    name='qty_available'
                                    rules={[{ required: true, message: 'Quantity is required' }]}
                                >
                                    <Input className="h-[40px]" />
                                </Form.Item>

                                {/* product description */}
                                <Form.Item
                                    className='col-span-2'
                                    label={<div className='flex gap-3'>
                                        <p>Product Description</p>
                                        {
                                            generatingAiDescription ?
                                                <div>
                                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: '#000' }} spin />} />
                                                </div> :
                                                <button type='button' className='py-[2px] px-[8px] border border-[#000] rounded-[40px] text-[12px] font-[500] text-[#344054]' onClick={generateAiDescription}>
                                                    Generate with Ai
                                                </button>
                                        }
                                    </div>}
                                    name='description'
                                    rules={[{ required: true, message: 'Description is required' }]}
                                >
                                    <Input.TextArea rows={3} />

                                </Form.Item>

                                {/* isTangible */}
                                <div>
                                    <Checkbox onChange={(e) => setIsTabgible(e?.target?.checked)}>
                                        Tangible?
                                    </Checkbox>
                                </div>
                            </div>

                            {/* buttons */}
                            <div className="mt-[40px] flex gap-4 items-center">
                                <Form.Item>
                                    <button className="px-[14px] py-2 rounded-[3px] border border-[#000] border-opacity-10 text-[#212020]" type='button' onClick={handleClose}>
                                        <p className="text-[12px] font-[600]">Cancel</p>
                                    </button>
                                </Form.Item>

                                <Form.Item>
                                    <button className="px-[14px] py-2 rounded-[3px] bg-[#552130] border border-[#552130] text-[#fff]">
                                        <p className="text-[12px] font-[600]">List Product</p>
                                    </button>
                                </Form.Item>
                            </div>
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </>
    );
};

export default AddProductModal;

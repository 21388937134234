import { Modal, Form, Input, notification, Upload, Spin, Checkbox } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { BaseServices } from '../../../helpers/baseServices';
import { StorageBox } from '../../../helpers/stroage';
import { IoCloseOutline } from 'react-icons/io5';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

interface modalProps {
    isOpened: boolean;
    handleClose: () => void;
    selectedProduct: any;
    handleReFetch: () => void;
}


const EditProductModal = ({ isOpened, handleClose, selectedProduct, handleReFetch }: modalProps) => {

    const [form] = useForm();
    const [productImages, setProductImages] = useState<any[]>([{ file: null, url: "" }, { file: null, url: "" }, { file: null, url: "" }, { file: null, url: "" }, { file: null, url: "" }]);
    const [isTabgible, setIsTabgible] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [generatingAiName, setGeneratingAiName] = useState(false);
    const [generatingAiDescription, setGeneratingAiDescription] = useState(false);

    const [fileList, setFileList] = useState<any[]>([]);

    //handleImageChange
    const handleChange = ({ fileList }: any) => {
        // Limit file list to a maximum of 5 files
        if (fileList.length <= 5) {
            setFileList(fileList);
        } else {
            notification.warning({
                message: 'Image Limit Exceeded',
                description: 'You can only upload up to 5 images.',
            });
        }
    };

    //uploadImage
    const uploadImage = async (file: any, index: number) => {
        if (file) {
            let data = new FormData();
            data.append('images', file);

            try {
                const response = await BaseServices.post(`/api/v1/elep/upload-item-images`, data);
                console.log('___imgUploadRes:', response?.data);
                const newImages = [...productImages];
                newImages[index] = { file, url: response?.data?.data?.[0] || '' };
                setProductImages(newImages);
                return response?.data?.data?.[0] || '';
            } catch (error: any) {
                console.log('___imgUploadErr:', error);
                notification.error({
                    message: 'Error',
                    description: error?.response?.data?.data || 'An error occurred while uploading image, please try again later',
                });
                return null;
            }
        }
    };

    //handlePreview
    const handlePreview = async (file: any) => {
        Modal.info({
            title: 'Image Preview',
            content: <img alt="preview" style={{ width: '100%' }} src={file?.url || file?.preview} />,
            okText: 'Close',
        });
    };

    //handleRemoveImage
    const handleRemoveImage = (file: any) => {
        setFileList((prevList) => prevList.filter((item: any) => item?.uid !== file?.uid));
    };

    //initialValues
    const initialValues = {
        name: selectedProduct?.name || "",
        price: selectedProduct?.price || "",
        qty_available: selectedProduct?.qty_available || "",
        description: selectedProduct?.description || ""
    }

    //onload
    useEffect(() => {
        if (selectedProduct) {
            const prodImages = selectedProduct?.images?.map((url: any) => ({
                file: null,
                url,
            }));

            setFileList([...prodImages]);
            setIsTabgible(selectedProduct?.is_tangible);
        }
    }, [selectedProduct]);


    //generateAiDescription
    const generateAiDescription = async () => {
        if (fileList?.length === 0) {
            notification.error({ message: "Error", description: "Please upload a product image" });
        } else {
            let data = new FormData();
            data.append("image", fileList?.[0]?.originFileObj);

            setGeneratingAiDescription(true);

            try {
                const response = await BaseServices.post(`/api/v1/elep/generate-description`, data);
                console.log("___generateAiDescriptionResponse", response?.data);
                form.setFieldsValue({ description: response?.data?.data?.description || "" });
                setGeneratingAiDescription(false);
            } catch (error: any) {
                console.log("___generateAiDescriptionError", error);
                notification.error({ message: "Error", description: error?.response?.data?.data || "An error occurred, please try again later" });
                setGeneratingAiDescription(false);
            }
        }
    }

    //generateAiName
    const generateAiName = async () => {
        if (fileList?.length === 0) {
            notification.error({ message: "Error", description: "Please upload a product image" });
        } else {
            let data = new FormData();
            data.append("image", fileList?.[0]?.originFileObj);

            setGeneratingAiName(true);

            try {
                const response = await BaseServices.post(`/api/v1/elep/generate-description`, data);
                console.log("___generateAiNameResponse", response?.data);
                form.setFieldsValue({ name: response?.data?.data?.name || "" });
                setGeneratingAiName(false);
            } catch (error: any) {
                console.log("___generateAiNameError", error);
                notification.error({ message: "Error", description: error?.response?.data?.data || "An error occurred, please try again later" });
                setGeneratingAiName(false);
            }
        }
    }


    //handleUpdateProduct
    const handleUpdateProduct = async (form_values: any) => {
        if (fileList.length === 0) {
            notification.error({
                message: 'No Images Uploaded',
                description: 'Please upload at least one image.',
            });
            return;
        }

        setIsCreating(true);

        try {
            // Separate existing images (with URL only) and new uploads (with originFileObj)
            const existingImages = fileList
                .filter((file: any) => file?.url) // Images with URLs are existing ones
                .map((file: any) => file.url);

            const newImages = fileList.filter((file: any) => file?.originFileObj); // New uploads

            // Upload only new images
            const uploadPromises = newImages.map((file: any, index: number) =>
                uploadImage(file.originFileObj, index)
            );
            const uploadedUrls = await Promise.all(uploadPromises);

            // Combine existing images and newly uploaded image URLs
            const finalImageUrls = [...existingImages, ...uploadedUrls.filter((url) => url)];

            // Prepare product data
            let productData = {
                '_id': selectedProduct?._id,
                'name': form_values?.name,
                'price': form_values?.price,
                'qty_available': form_values?.qty_available,
                'description': form_values?.description,
                'is_tangible': isTabgible,
                'images': finalImageUrls,
            };

            console.log('___updated_product', productData);

            setTimeout(() => {
                const old_data = StorageBox.retrieveProducts();
                const filtered_data = old_data.filter(
                    (item: any) => item?._id !== selectedProduct?._id
                );
                StorageBox.saveNewProduct([...filtered_data, productData]);
                setIsCreating(false);
                notification.success({
                    message: 'Success',
                    description: 'Product updated successfully',
                });
                setFileList([]);
                handleClose();
                handleReFetch();
            }, 2000);
        } catch (error) {
            console.error('Error updating product:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to update the product. Please try again.',
            });
            setIsCreating(false);
        }
    };


    return (
        <>
            <Modal
                open={isOpened}
                onClose={handleClose}
                keyboard={false}
                maskClosable={false}
                closeIcon={null}
                width={700}
                centered
                footer={null}
            >
                <div className="h-[500px] overflow-y-auto px-3">
                    <Spin spinning={isCreating}>
                        <div className="flex justify-between items-center">
                            <p className='text-[20px] font-[600]'>Product update</p>
                            <button className='h-6 w-6 hover:bg-[#000] hover:bg-opacity-10 rounded flex justify-center items-center' onClick={handleClose}>
                                <IoCloseOutline className='h-5 w-5' />
                            </button>
                        </div>

                        <Form layout='vertical' form={form} onFinish={handleUpdateProduct} initialValues={initialValues} disabled={isCreating}>
                            {/* product images */}
                            <Form.Item className='mt-5'>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    onRemove={handleRemoveImage}
                                    multiple
                                    beforeUpload={() => false} // Disable automatic upload
                                >
                                    {fileList.length < 5 && (
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>Upload</div>
                                        </div>
                                    )}
                                </Upload>
                            </Form.Item>

                            {/* productName */}
                            <Form.Item
                                label={<div className='flex gap-3'>
                                    <p>Product Name</p>
                                    {
                                        generatingAiName ?
                                            <div>
                                                <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: '#000' }} spin />} />
                                            </div> :
                                            <button type='button' className='py-[2px] px-[8px] border border-[#000] rounded-[40px] text-[12px] font-[500] text-[#344054]' onClick={generateAiName}>
                                                Generate with Ai
                                            </button>
                                    }
                                </div>}
                                name='name'
                                rules={[{ required: true, message: 'Product name is required' }]}
                            >
                                <Input className="h-[40px]" />
                            </Form.Item>

                            <div className="mt-[14px] 2xl:grid xl:grid lg:grid md:grid grid-cols-2 gap-x-5 items-center">
                                {/* price */}
                                <Form.Item
                                    label='Product Price'
                                    name='price'
                                    rules={[{ required: true, message: 'Price is required' }]}
                                >
                                    <Input className="h-[40px]" />
                                </Form.Item>

                                {/* quantity */}
                                <Form.Item
                                    label='Product Quantity'
                                    name='qty_available'
                                    rules={[{ required: true, message: 'Quantity is required' }]}
                                >
                                    <Input className="h-[40px]" />
                                </Form.Item>

                                {/* product description */}
                                <Form.Item
                                    className='col-span-2'
                                    label={<div className='flex gap-3'>
                                        <p>Product Description</p>
                                        {
                                            generatingAiDescription ?
                                                <div>
                                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: '#000' }} spin />} />
                                                </div> :
                                                <button type='button' className='py-[2px] px-[8px] border border-[#000] rounded-[40px] text-[12px] font-[500] text-[#344054]' onClick={generateAiDescription}>
                                                    Generate with Ai
                                                </button>
                                        }
                                    </div>}
                                    name='description'
                                    rules={[{ required: true, message: 'Description is required' }]}
                                >
                                    <Input.TextArea rows={3} />

                                </Form.Item>

                                {/* isTangible */}
                                <div>
                                    <Checkbox checked={isTabgible} onChange={(e) => setIsTabgible(e?.target?.checked)}>
                                        Tangible?
                                    </Checkbox>
                                </div>
                            </div>

                            {/* buttons */}
                            <div className="mt-[40px] flex gap-4 items-center">
                                <Form.Item>
                                    <button className="px-[14px] py-2 rounded-[3px] border border-[#000] border-opacity-10 text-[#212020]" type='button' onClick={handleClose}>
                                        <p className="text-[12px] font-[600]">Cancel</p>
                                    </button>
                                </Form.Item>

                                <Form.Item>
                                    <button className="px-[14px] py-2 rounded-[3px] bg-[#552130] border border-[#552130] text-[#fff]">
                                        <p className="text-[12px] font-[600]">Update Product</p>
                                    </button>
                                </Form.Item>
                            </div>
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </>
    );
};

export default EditProductModal;

import axios from "axios";
import { BASE_URL } from "./constants";

const token = localStorage.getItem("access_token");

const headers = token
  ? {
      Authorization: `Bearer ${token}`,
    }
  : {};

export const BaseServices = axios.create({
  baseURL: `${BASE_URL}`,
  headers: headers,
  // withCredentials: true,
});

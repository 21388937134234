import React from 'react'
import { Link } from 'react-router-dom'


const JoinWaitList = () => {
    return (
        <>
            <div className="w-full py-[30px] max-w-screen-xl mx-auto px-4">
                <p className='text-[50px] font-[500] text-black text-center'>
                    Join the waitlist
                </p>

                <p className='text-[16px] font-[400] text-[#475467] text-center mt-[16px]'>
                    Be the first to know when peacemaker goes live. Join our waitlist for updates and early access
                </p>

                <div className="mt-[40px] flex justify-center">
                    <Link className='px-[20px] py-[12px] rounded-[8px] bg-[#552130] text-white' to="https://forms.gle/D2vLKJ9YoafQNq2e7" target='_blank'>
                        <p>Join now</p>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default JoinWaitList

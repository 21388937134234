import React from "react";
import { Link } from "react-router-dom";

const GetStartedSection: React.FC = () => {
  return (
    <div
      id="started"
      className="w-full h-[665px] flex justify-center items-center px-4"
    >
      <div className="w-full max-w-[1218px] h-[385px] bg-[#662424] rounded-[20px] flex flex-col justify-center items-center p-8">
        <div className="text-white text-[50.66px] font-normal font-['Imprima'] leading-[66px] text-center">
          Get Started with Secure Payments
        </div>
        <div className="mt-[26px] flex gap-3">
          <button className="px-[18px] py-2.5 rounded shadow border border-white flex justify-center items-center text-white text-base font-semibold font-['Inter']">
            Contact Us
          </button>
          <Link
            to="https://app.peacemaker.cash"
            className="px-[18px] py-2.5 bg-white rounded-lg shadow border border-[#cfd4dc] flex justify-center items-center text-center text-[#344053] text-base font-semibold font-['Inter'] w-[143px]"
          >
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GetStartedSection;

import { Modal, Tag } from "antd";

interface modalProps {
    isOpened: boolean;
    handleClose: () => void;
    selectedProduct: any;
}

const ProductPreviewModal = ({ isOpened, handleClose, selectedProduct }: modalProps) => {
    return (
        <>
            <Modal open={isOpened} onCancel={handleClose} footer={null}>
                <div>
                    <p className="text-[20px] font-[500]">{selectedProduct?.name}</p>

                    {/* images */}
                    <div className="flex flex-wrap gap-5 items-center mt-5">
                        {
                            selectedProduct?.images?.map((imgUrl: string, index: number) => (
                                <img key={index} src={imgUrl} alt="product" className="w-[100px] h-[100px] object-cover rounded-[6px]" />
                            ))
                        }
                    </div>

                    <hr className="my-5" />

                    <div className="flex items-center justify-between mt-2">
                        <p className="text-[16px] font-[400]">Price</p>
                        <p className="text-[16px] font-[400]">GHS {Number(selectedProduct?.price).toFixed(2)}</p>
                    </div>

                    <div className="flex items-center justify-between mt-2">
                        <p className="text-[16px] font-[400]">Qty In Stock</p>
                        <p className="text-[16px] font-[400]">{selectedProduct?.qty_available}</p>
                    </div>

                    <div className="mt-3">
                        {
                            selectedProduct?.is_tangible && <Tag color="blue">Tangible</Tag>
                        }
                    </div>

                    <hr className="my-5" />

                    <p className="text-[14px] font-[400] mt-2 text-gray-500">
                        {selectedProduct?.description}
                    </p>

                </div>
            </Modal >
        </>
    )
}

export default ProductPreviewModal

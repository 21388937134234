import AboutSection from "../components/about";
import AppSection from "../components/app";
import BranchSection from "../components/branches";
import FooterSection from "../components/footer";
import HowSection from "../components/how";
import JoinWaitList from "../components/joinWaitList";
import NewsLetterSection from "../components/new";
import HeaderSection from "../components/newHeaderSection";
import GetStartedSection from "../components/started";

const HomePage = () => {
  return (
    <div>
      {/* Header */}
      <HeaderSection />

      {/* About section */}
      <AboutSection />

      {/* How It Works */}
      <HowSection />

      {/* Branch section */}
      <BranchSection />

      {/* App Section */}
      <AppSection />

      {/* waitlist */}
      <JoinWaitList />

      {/* News Letter */}
      <NewsLetterSection />

      {/* Get Started */}
      <GetStartedSection />

      {/* Footer section */}
      <FooterSection />
    </div>
  );
};

export default HomePage;
